<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">콜백통계</h1>
    <!-- 상단 조회폼 시작 -->
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <div>
          <div class="form-inp sm">
            <v-select
                :items="this.$store.getters['userStore/GE_USER_ROLE'].company"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="aspNewcustKey"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="접수일자"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  @change="startDate"
              ></v-date-picker>
              <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  :min="dates[0]"
                  @change="endDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
              >확인
              </v-btn>
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select
                :items="dropItems"
                item-text="name"
                item-value="value"
                outlined
                label="처리구분"
                name="EOT_TY"
                placeholder="선택하세요"
                v-model="eotTy"
            >
            </v-select>
          </div>

        </div>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" v-on:click="submit">조회</v-btn>
      </div>
    </div>
    <!-- 상단 조회폼 끝   -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        콜백통계 Report
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')" outlined class="btn-point" id="btnObndExcel"
                 @click="excelDownload('divGridClbkSttc', '콜백톻계')">
            엑셀 다운로드
          </v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            height="553px"
            :headers="divGridClbkSttc"
            id="divGridClbkSttc"
            :items="gridDataText"
            :items-per-page="30"
            item-key="CLBK_NO"
            :page.sync="page"
            single-select
            hide-default-footer
            class="grid-default case03"
            :item-class="isActiveRow2"
            v-on:click:row="getGrid1ListSel"
            v-model="selectedList"
            @page-count="pageCount = $event"
            no-data-text="검색된 결과가 없습니다."
        >
          <!--          <template v-slot:[`item.CLBK_TEL_NO`]="{ item }" >-->
          <!--            <div>{{ phoneHyphen(item.CLBK_TEL_NO) }}</div>//접수전화번호-->
          <!--          </template>-->
          <!--          <template v-slot:[`item.IN_DATE`]="{ item }" >-->
          <!--            <div>{{ dateHyphen(item.IN_DATE) }}</div>//접수일자-->
          <!--          </template>-->
          <!--          <template v-slot:[`item.UPDATE_DATE`]="{ item }" >-->
          <!--            <div>{{ dateHyphen(item.UPDATE_DATE) }}</div>//처리일자l-->
          <!--          </template>-->
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from "@/mixin/mixin.js";

export default {
  name      : "MENU_M710104", //name은 'MENU_' + 파일명 조합
  components: {},
  mixins    : [mixin], // mixin 등록
  data() {
    return {
      popDate      : false,
      headers      : {},
      sendData     : {},
      aspNewcustKey: '',
      eotTy        : '',
      dateFrom     : '',
      dateTo       : '',
      selectedList       : '',

      dropItems: [
        {name: "전체", value: ""},
        {name: "처리완료", value: "00001"},
        {name: "미시도", value: "00002"},
      ],
      menu1    : false,
      dates    : [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],

      //msgList
      msgList: {
        noAspCustKey : "회사구분란에서 회사를 선택후 이용해주십시오",
        excelDwnError: "[엑셀출력 실패] 조회된 정보가 없습니다.",
      },

      divGridClbkSttc: [
        {
          text    : '접수일자',
          value   : 'IN_DATE',
          align   : 'center',
          sortable: false,
        },
        {
          text    : '접수시간',
          value   : 'IN_TIME',
          align   : 'center',
          sortable: false,
        },
        {
          text    : '처리일자',
          value   : 'UPDATE_DATE',
          align   : 'center',
          sortable: false,

        },
        {
          text    : '처리시간',
          value   : 'UPDATE_TIME',
          align   : 'center',
          sortable: false,
        },
        {
          text    : '생년월일',
          value   : 'CUST_NO',
          align   : 'center',
          sortable: false,
        },
        {
          text    : '인가번호',
          value   : 'INGNO',
          align   : 'center',
          sortable: false,
        },
        {
          text    : '접수전화번호',
          value   : 'CLBK_TEL_NO',
          align   : 'center',
          sortable: false,
        },
        {
          text    : '처리구분',
          value   : 'EOT_TY_NM',
          align   : 'center',
          sortable: false,
        },
        {
          text    : '시도횟수',
          value   : 'TRY_CNT',
          align   : 'center',
          sortable: false,
        },
        {
          text    : '사용자',
          value   : 'AGENT_NM',
          align   : 'center',
          sortable: false,
        },
        {
          text    : '콜백번호',
          value   : 'CLBK_NO',
          align   : 'center',
          sortable: false,
        },
      ],
      gridDataText   : [],
      detailData   : {},
      page           : 1,
      pageCount      : 0,
      itemsPerPage   : 20,
      totalVisible   : 10,
    };
  },
  methods: {
    async submit() {
      if(this.aspNewcustKey == null || this.aspNewcustKey == ''){
        this.common_alert(this.msgList.noAspCustKey, 'error');
        return;
      }

      let requestData = {
        headers : {},
        sendData: {}
      };
      requestData.headers["URL"] = "/api/statistics/phone/callback/list";
      requestData.headers["SQL_NM"] = "list";
      requestData.headers["GRID_ID"] = "divGridClbkSttc";
      requestData.headers["SERVICE"] = "statistics.phone.callback";
      requestData.headers["MENU_ID"] = "";
      requestData.headers["TYPE"] = "XML_SERVICE";
      requestData.headers["ASYNC"] = false;

      requestData.sendData = {};
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      requestData.sendData["DATE_FROM"] = this.formatDate2(this.dates[0]);
      requestData.sendData["DATE_TO"] = this.formatDate2(this.dates[1]);
      requestData.sendData["EOT_TY"] = this.eotTy;

      let response = await this.common_postCall(requestData);
      this.submitCallBack(response);
    },
    submitCallBack(response) {
      let data = response.DATA;
      this.gridDataText = data;

      for (let i in this.gridDataText) {
        this.gridDataText[i]["IN_DATE"] = this.formatDate3(this.gridDataText[i]["IN_DATE"]);
        this.gridDataText[i]["UPDATE_DATE"] = this.formatDate3(this.gridDataText[i]["UPDATE_DATE"]);
        this.gridDataText[i]["DSTR_DATE"] = this.formatDate3(this.gridDataText[i]["DSTR_DATE"]);
        this.gridDataText[i]["IN_TIME"] = this.formatTime(this.gridDataText[i]["IN_TIME"]);
        this.gridDataText[i]["DSTR_TIME"] = this.formatTime(this.gridDataText[i]["DSTR_TIME"]);
        this.gridDataText[i]["UPDATE_TIME"] = this.formatTime(this.gridDataText[i]["UPDATE_TIME"]);
        this.gridDataText[i]["CLBK_TEL_NO"] = this.phoneHyphen(this.gridDataText[i]["CLBK_TEL_NO"]);
        this.gridDataText[i]["SEND_MAN_NO"] = this.phoneHyphen(this.gridDataText[i]["SEND_MAN_NO"]);
      }
      let idx = 1;
      let dataText = this.gridDataText;
      for (let i in dataText) {
        this.gridDataText[i]["index"] = idx++;
      }
      //this.selectedList =[];
      console.log(data);

    },
    getGrid1ListSel(item){
      this.detailData = JSON.parse(JSON.stringify(item));
    },
    isActiveRow2(item){
      return item.CLBK_NO== this.detailData.CLBK_NO? 'active':'';
    },
    excelDownload(table_id, file_name) {
      if (table_id === 'divGridClbkSttc' && this.gridDataText.length < 1) {
        this.common_alert(this.msgList.excelDwnError, 'error')
        return false
      }
      this.table_id = table_id
      this.file_name = file_name
      this.mixin_common_exportExcel()
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    formatDate2(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}${month}${day}`
    },
    formatDate3(date) {
      if (!date) return null
      let year = date.substr(0, 4);
      let month = date.substr(4, 2);
      let day = date.substr(6, 2);

      return `${year}/${month}/${day}`
    },
    formatTime(time) {
      if (!time) return null
      let hh = time.substr(0, 2);
      let mm = time.substr(2, 2);
      let ss = time.substr(4, 2);

      return `${hh}:${mm}:${ss}`
    },

    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    phoneHyphen(number) {
      let phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },
    dateHyphen(number) {
      let phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },
  },

  computed: {
    initHeaders() {
      return {
        SERVICE: '',
        METHOD : "",
        TYPE   : 'BIZ_SERVICE',
      };
    },
    dateRangeText() {
      let newStartDate = this.dates[0];
      let newEndDate = this.dates[1];
      let returnVal = "";
      if (newStartDate !== '' && newEndDate !== '') {
        returnVal = newStartDate + " ~ " + newEndDate
      }
      return returnVal;
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    computedDateFormatted2() {
      return `${this.date} ${this.timeStep}`
    }
  },
};
</script>

<style></style>
